@font-face {
    font-family: 'IRANSansMobile(FaNum)';
    src: local('IRANSansMobile(FaNum)'), url(../../static/media/IRANSans.66917783.ttf) format('truetype');
}

@font-face {
    font-family: 'IRANSansMobile';
    src: local('IRANSansMobile'), url(../../static/media/IRANSans-En.d41d8266.ttf) format('truetype');
}

/*@font-face {*/
/*font-family: 'Material Icons';*/
/*src: local('MaterialIcons'), url('./../node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf') format('truetype');*/
/*}*/

/*@font-face {*/
/*font-family: 'MaterialCommunityIcons';*/
/*src: local('MaterialCommunityIcons'), url('./../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf') format('truetype');*/
/*}*/
* {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    font-family: "IRANSansMobile(FaNum)", IRANSansMobile, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

::-webkit-scrollbar {
    height: 2px;
    width: 2px;
    background: rgba(0,0,0,.005);
}

::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.2);
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-corner {
    background: rgba(0,0,0,.2);
}

.dp-leaflet-container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}


html,
body {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    /* Disables pull-to-refresh but allows overscroll glow effects. */
    /*overscroll-behavior-y: contain;*/

    /* Disables pull-to-refresh and overscroll glow effect.
     Still keeps swipe navigations. */
    overscroll-behavior-y: none;
    position: fixed;
    overflow: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*disable select*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#root {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

